import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/shared/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { About } from './components/About';
import { Products } from './components/Products';
import { Services } from './components/Services';
import { Contact } from './components/Contact';
import { Ro_water_purifier } from './components/products/Ro_water_purifier';
import { Water_Filter } from './components/products/Water_Filter';
import { Water_Purifier_Parts } from './components/products/Water_Purifier_Parts';
import { Blog_articles } from './components/blogsarticles/Blog_articles';
import { Distributers } from './components/Distributers';
import { Ourprojects } from './components/Ourprojects';

//import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
            <Route path='/fetch-data' component={FetchData} />
            <Route path='/about' component={About} />
            <Route path='/products' component={Products} />
            <Route path='/blog_articles' component={Blog_articles} />
            <Route path='/ro_water_purifier' component={Ro_water_purifier} />
            <Route path='/water_filter' component={Water_Filter} />
            <Route path='/water_purifier_parts' component={Water_Purifier_Parts} />
            <Route path='/services' component={Services} />
            <Route path='/contact' component={Contact} />
            <Route path='/distributers' component={Distributers} />
            <Route path='/ourprojects' component={Ourprojects} />

      </Layout>
    );
  }
}
